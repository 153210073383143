<template>
  <section>
    <v-row class="fill-height">
      <v-col>
        <v-btn
          color="primary"
          :to="`/site/${selectedSite.siteId}/enduse/create`"
        >
          <v-icon left>mdi-plus-thick</v-icon>New End Use
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-autocomplete
          :items="sites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          label="Select site"
          return-object
          @change="handleGetEndUses"
          solo
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle v-model="toggleView">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>apps</v-icon>
              </v-btn>
            </template>
            <span>Show Card View</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>list_alt</v-icon>
              </v-btn>
            </template>
            <span>Show List View</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-if="noDevices">
      <v-card
        class="card-center"
        flat
        tile
        style="background-color: #ecf2f6 !important; font-weight: 800;"
      >
        No {{ typeName }} configured
      </v-card>
    </v-row>
    <confirm-dialog
      ref="confirmDelete"
      title="Delete End Use?"
    ></confirm-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredEndUses"
      class="elevation-1"
      :options="defaultOptions"
      v-if="filteredEndUses.length > 0"
    >
      <!-- <template v-slot:[`item.hasRegisters`]="{ item }">
        <td>
          <v-icon v-if="item.hasRegisters === true" color="green darken-1"
            >check_circle_outline</v-icon
          >
          <v-icon color="red darken-1" v-else>block</v-icon>
        </td>
      </template> -->
      <!-- <template v-slot:[`item.hasRegisterEndUses`]="{ item }">
        <td>
          <v-icon v-if="item.hasRegisterEndUses === true" color="green darken-1"
            >check_circle_outline</v-icon
          >
          <v-icon v-else color="red darken-1">block</v-icon>
        </td>
      </template> -->
      <template v-slot:[`item.id`]="{ item }">
        <td>
          <v-btn
            :to="`/site/${item.siteId}/enduse/${item.endUseId}/monitor/${item.monitorId}/register/${item.registerId}/edit`"
            @click="editItem(item)"
            icon
          >
            <v-icon medium>edit</v-icon>
          </v-btn>
          <v-btn icon @click="handleDelete(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template>

      <!-- <template v-slot:top>
        <v-row class="ml-1">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              v-if="$can('delete', 'meters')"
              tooltip="Delete Selected Meters"
              icon
              @click="handleDelete"
              :disabled="selectedMeters.length === 0"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template> -->
    </v-data-table>
  </section>
</template>
<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "../_api";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
export default {
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  data() {
    return {
      typeName: "End Uses",
      titleIcon: "account_tree",
      sites: [],
      selectedSite: {},
      meters: [],
      selectedMeters: [],
      filteredEndUses: [],
      headers: [
        {
          text: "Name",
          value: "endUseName",
        },
        {
          text: "Monitor Name",
          value: "monitorName", //need monitor name
        },
        {
          text: "Parent",
          value: "parentName", //need parent name
        },
        {
          text: "Register",
          value: "registerName",
        },
        {
          text: "Resource Type",
          value: "resourceTypeName",
        },
        {
          text: "Register Unit of Measure",
          value: "registerUnitOfMeasureName",
        },
        {
          text: "End Use Unit of Measure",
          value: "endUseUnitOfMeasureName",
        },
        {
          text: "Phase",
          value: "phaseName",
        },
        { text: "Actions", value: "id", sortable: false },
      ],
      defaultOptions: {
        // sortBy: ["version"],
        // sortDesc: true,
      },
    };
  },
  async created() {
    this.sites = await api.getSites();

    if (this.defaultSite !== null) {
      this.selectedSite = this.defaultSite;
      this.filteredEndUses = await api.getEndUsesForSite(
        this.selectedSite.siteId
      );
    }
  },
  methods: {
    ...mapActions({
      setDefaultSite: "session/setDefaultSite",
    }),
    toggleView() {},
    editItem() {},

    async handleDelete(endUse) {
      const msg = `Are you sure you want to delete ${endUse.endUseName} and its associated data?  Deleting an End Use cannot be undone.`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      console.log(endUse);

      try {
        await api.deleteEndUse(endUse);
        this.filteredEndUses = await api.getEndUsesForSite(
          this.selectedSite.siteId
        );
      } catch (error) {
        console.error(error);
      }
    },

    upload(item) {
      console.log(item);
    },

    async handleGetEndUses() {
      this.setDefaultSite(this.selectedSite);
      this.filteredEndUses = await api.getEndUsesForSite(
        this.selectedSite.siteId
      );
    },
  },
  computed: {
    ...mapGetters({
      defaultSite: "session/defaultSite",
    }),
    noDevices: function () {
      return this.filteredEndUses.length === 0;
    },
  },
};
</script>